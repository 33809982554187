import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import Prism from "prismjs/prism";
import theme from "prism-react-renderer/themes/github";

// Note: Tried loadLanguages but it kept throwing an error
// https://github.com/PrismJS/prism/pull/1359

// To get list of used languages in the posts folder:
// rg -I --no-line-number '```\w' | sort | uniq | tr -d ' ' | awk -F'`' '{ print $4}' | sort

// look in: node_modules/prismjs/components
require("prismjs/components/prism-bash");
require("prismjs/components/prism-elixir");
require("prismjs/components/prism-json");
// required by php
// https://github.com/PrismJS/prism/issues/1400#issuecomment-485847919
require("prismjs/components/prism-markup-templating");
require("prismjs/components/prism-php");
require("prismjs/components/prism-php-extras");
require("prismjs/components/prism-rust");
require("prismjs/components/prism-ruby");
require("prismjs/components/prism-vim");

//console.log(
//  Object.keys(Prism.languages)
//    .filter((a) => a !== "extend" && a !== "insertBefore")
//    .join("\n")
//);

export const Code = ({ codeString, language, ...props }) => {
  return (
    <Highlight
      {...defaultProps}
      Prism={Prism}
      code={codeString}
      language={language}
      theme={theme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={style}>
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};
